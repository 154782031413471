export default {
    areaDataBegin(state) {
        state.loadingArea = true;
    },
    areaDataSuccess(state, data) {
        state.area = data;
        state.loadingArea = false;
    },
    areaDataErr(state) {
        state.loadingArea = false;
    },
    timeBlocksDataBegin(state) {
        state.loadingTimeBlocks = true;
    },
    timeBlocksDataSuccess(state, data) {
        state.timeBlocks = data.data;
        state.countTimeBlocks = data.total;
        state.currentPageTimeBlocks = data.current_page;
        state.loadingTimeBlocks = false;
    },
    timeBlocksDataErr(state) {
        state.loadingTimeBlocks = false;
    },
    leadDataBegin(state) {
        state.loadingLead = true;
    },
    leadDataSuccess(state, data) {
        state.lead = data;
        state.loadingLead = false;
    },
    leadDataErr(state) {
        state.loadingLead = false;
    },
    leadsDataBegin(state) {
        state.loadingLeads = true;
    },
    leadsDataSuccess(state, data) {
        state.leads = data.data;
        state.count = data.meta.total;
        state.currentPage = data.meta.current_page;
        state.loadingLeads = false;
    },
    leadsDataErr(state) {
        state.loadingLeads = false;
    },
    setCurrentPageLeads(state, page) {
        state.currentPage = page;
    },
    areasDataBegin(state) {
        state.loadingAreas = true;
    },
    areasDataSuccess(state, data) {
        state.areas = data.data;
        state.countArea = data.total;
        state.currentPageArea = data.current_page;
        state.loadingAreas = false;
    },
    areasDataErr(state) {
        state.loadingAreas = false;
    },
    setCurrentPageAreas(state, page) {
        state.currentPageArea = page;
    },
    setCurrentPageTimeBlocks(state, page) {
        state.currentPageArea = page;
    },
    areasCreateDataBegin(state) {
        state.loadingAreaCreate = true;
    },
    areasCreateDataSuccess(state) {
        state.loadingAreaCreate = false;
    },
    areasCreateDataErr(state) {
        state.loadingAreaCreate = false;
    },
    timeBlockCreateDataBegin(state) {
        state.loadingTimeBlockCreate = true;
    },
    timeBlockCreateDataSuccess(state) {
        state.loadingTimeBlockCreate = false;
    },
    timeBlockCreateDataErr(state) {
        state.loadingTimeBlockCreate = false;
    },
    assignLeadsDataBegin(state) {
        state.loadingAssign = true;
    },
    assignLeadsDataSuccess(state) {
        state.loadingAssign = false;
    },
    assignLeadsDataErr(state) {
        state.loadingAssign = false;
    },
};
